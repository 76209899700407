import { useLayoutEffect, useState } from 'react';

import { type Program } from '../../types/program';
import { fromMediaDTO } from '../../utils/api-dto';
import { loadImageAsPromise, MediaUtils } from '../../utils/media';
import { VolumeIcon } from '../icons/VolumeIcon';
import { VolumeMuteIcon } from '../icons/VolumeMuteIcon';
import {
  OrgSubscriptionUpgradeIcon,
  useMyOrganizationFeatureChecker,
} from '../Organization';

export function ProgramCard(props: {
  program: Program;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  onClick?: () => void;
  onClose?: () => void;
}): JSX.Element {
  const { program, muted, setMuted, onClick } = props;
  const programsAccess = useMyOrganizationFeatureChecker().canAccessProgram(
    program.id
  );

  const iconUrl = program.basicSettings?.icon?.media?.url;
  const coverImageUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.cover?.media)
  );
  const trailerUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.trailer?.media)
  );
  const trailerPosterUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.trailer?.media),
    { videoThumbnail: 'first' }
  );

  useLayoutEffect(() => {
    if (!trailerPosterUrl) return;
    loadImageAsPromise(trailerPosterUrl);
  }, [trailerPosterUrl]);

  const [hover, setHover] = useState(false);

  const onPointerEnter = () => {
    setHover(true);
  };

  const onPointerLeave = () => {
    setHover(false);
  };

  const showTrailer = hover && trailerUrl;

  return (
    <div
      className={`flex flex-col w-100 flex-shrink-0 cursor-pointer`}
      onClick={onClick}
    >
      <div
        className='relative'
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        <div className='aspect-w-16 aspect-h-9'>
          <img
            src={coverImageUrl ?? undefined}
            className={`object-cover rounded-xl flex-shrink-0 w-full h-full ${
              showTrailer ? 'hidden' : 'flex'
            }`}
            alt='cover'
          />
          {showTrailer && (
            <>
              <video
                className='object-cover rounded-xl flex-shrink-0 w-full h-full'
                src={trailerUrl}
                muted={muted}
                autoPlay
                loop
                playsInline
                poster={trailerPosterUrl ?? undefined}
              />
            </>
          )}
        </div>
        {showTrailer && (
          <div className='absolute top-3 right-3 flex items-center gap-3'>
            <button
              type='button'
              className='btn w-8 h-8 flex items-start justify-end'
              onClick={(e) => {
                setMuted(!muted);
                e.stopPropagation();
              }}
            >
              {muted ? (
                <VolumeMuteIcon className='w-3.5 h-3.5 fill-current' />
              ) : (
                <VolumeIcon className='w-3.5 h-3.5 fill-current' />
              )}
            </button>
          </div>
        )}
      </div>
      <div className='flex items-center'>
        {iconUrl && (
          <img src={iconUrl} alt='icon' className='w-7.5 h-7.5 mr-2' />
        )}
        <div className='flex-1 pt-1 flex items-start justify-between gap-2'>
          <div className='text-white text-xl font-medium'>{program.name}</div>
          {!programsAccess && (
            <div className='pt-px'>
              <OrgSubscriptionUpgradeIcon className='w-6 h-6 p-0.25' />
            </div>
          )}
        </div>
      </div>
      <div className='text-icon-gray text-sms h-10'>
        {program.basicSettings?.shortDescription}
      </div>
    </div>
  );
}
